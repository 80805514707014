import * as React from "react";

import Puzzle from "../../Puzzle";

import kino from "../../images/kino.jpg";
import map from '../../images/m3.png'
import audio from '../../../static/a3.mp3'

const Page = () => {
  return (
    <Puzzle
      title="Zagadka 3"
      author="Autorka: Iwona Kałuża"
      imageSrc={kino}
      imageAlt="szkic elewacji budynku"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      audio={audio}
      language="pl"
      languageUrl="/riddles/uoGoOLQfo5"
    >
      <div>
        <p>
          Wróćmy do końca lat 60. XX wieku. Dokładnie w 1969 roku powstał
          projekt techniczny kina Warszawa. W jego wnętrzu amfiteatralna
          widownia na 650 miejsc, komfortowa poczekalnia, szerokie schody, sala
          rekreacyjna oraz bufet. Do początku lat 90. jedno z
          najnowocześniejszych wrocławskich kin.{" "}
        </p>
        <p>
          Jego architekt Andrzej Łukaszewicz we współpracy z Mieczysławem
          Rychlickim budowali na zastanych fundamentach XIX-wiecznego
          Palast-Theater. W powojennym, odbudowywanym przez nich budynku szkło
          miało pojawić się na elewacji.{" "}
        </p>
        <p>
          Nakreślając kontekst szkła, jako materiału używanego w architekturze,
          trzeba powiedzieć o tym, jak szkło ewoluowało. Od pełnych blasku
          średniowiecznych witraży, poprzez popularne w PRLu witromozaiki i
          luksfery, po obecne szklane wieżowce. Synonim nowoczesności i
          prestiżu. Gdzież są twoje szklane domy? - pisał Stefan Żeromski w
          „Przedwiośniu”.
        </p>
        <p>
          W kinie Warszawa elewacja miała być zdobiona okładziną wykonaną z
          odpadów przetopionego szkła. By wszystko było spójne wymiary tafli
          szklanych zostały dostosowane do wielkości otworów okiennych.
          Prowadzono rozmowy. Wydawać by się mogło, że plan był idealny
          -bazujacy na niewykorzystanym materiale. W finale jednak dyrekcja huty
          szkła w Wałbrzychu zmieniła zdanie, a architekci jako zadośćuczynienie
          mieli zadowolić się szklaną dekoracją osłaniającego wejście parawanu.
          W te działania zaangażował się wrocławski projektant szkła - Zbigniew
          Horbowy. Przy czym i tutaj wszystko okazało się artystyczną mrzonką.
          To co jest obecnie widoczne, to ceramiczna, kobaltowa okładzina
          parteru kina, która nie ma nic wspólnego z pierwotnym projektem
          Łukaszewicza i Horbowego.{" "}
        </p>
        <p>
          Ceramika była obecna również we wnętrzu. Choć i ta realizacja nie oddała
          początkowych założeń. Zamiast wizji projektowej Mieczysława
          Rychlickiego hol kasowy i inne wnętrza wypełniły białe, potłuczone
          przez robotników ceramiczne płytki. Kolejny przykład niespełnionych w
          owym czasie projektowych aspiracji.{" "}
        </p>
        <p>
          Kino „Warszawa" to historia wrocławskich ambicji i manifestacji. Chęci
          pokazania swojego miejsca na kulturalnej mapie Wrocławia. Czegoś co
          działo się w opozycji do dawnego, przedwojennego i „niemieckiego”
          Palast-Theater. Patrząc na całość - nowoczesność była we wnętrzu.
          Elewacja zewnętrzna to przykład niemożności realizacji, marzeń o
          recyklingu i wykorzystaniu szkła jako elementu dekoracji w powojennej
          architekturze.{" "}
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
